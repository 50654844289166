<template>
  <div class="flex flex-col">
    <h1 class="text-theme-sidebr text-2xl font-poppins mb-1">
      {{ $t(translate.mainTitle) }}
    </h1>
    <ValidationObserver ref="form">
      <div class="flex justify-between items-center">
        <validation-provider
          #default="{ errors }"
          :rules="'required'"
          :name="$t(translate.title)"
        >
          <b-form-group>
            <b-form-input
              v-model="templateInfo.title"
              :placeholder="$t(translate.title)"
            />
            <small class="text-danger" v-text="errors[0]" />
          </b-form-group>
        </validation-provider>
        <div class="flex gap-2 items-center">
          <b-button
            v-if="templateImages.results.length > 0"
            v-b-toggle.sidebar-right
            variant="dark"
          >
            <span>View Photos</span>
          </b-button>
          <button
            v-if="$route.params.uuid"
            @click="saveTemplateInfo"
            :disabled="processing"
            class="flex text-white justify-center focus:outline-none bg-gray-900 c-p-3 rounded-md"
          >
            <span v-if="processing">{{ $t("Message.loading") }}</span>
            <span v-else>
              {{ $t(translate.updateDesign) }}
            </span>
          </button>
          <button
            v-else
            @click="saveTemplateInfo"
            :disabled="processing"
            class="flex text-white justify-center focus:outline-none bg-theme-teal c-p-3 rounded-md"
          >
            <span v-if="processing">{{ $t("Message.loading") }}</span>
            <span v-else>
              {{ $t(translate.saveTemDesign) }}
            </span>
          </button>
        </div>
      </div>
    </ValidationObserver>

    <div class="h-screen">
      <EmailEditor
        class="h-screen"
        :min-height="minHeight"
        :locale="localEditor"
        :tools="tools"
        ref="emailEditor"
        v-on:load="editorLoaded"
        v-on:ready="editorReady"
      />
    </div>
    <div class="flex mt-3">
      <div class="mx-auto flex gap-3 md:px-10">
        <!-- <div>
            <button @click="saveTemplateInfo" class="flex text-white justify-center focus:outline-none bg-theme-teal c-p-3 rounded-md">Save Design Template</button>
        </div> -->
      </div>
    </div>

    <b-sidebar id="sidebar-right" title="Uploaded Photos" right shadow>
      <div class="container px-3 py-2">
        <div class="grid grid-cols-1">
          <div
            class="relative flex flex-col mb-1"
            v-for="image in templateImages.results"
            :key="image.uuid"
          >
            <b-img :src="image.image" fluid thumbnail></b-img>
            <span class="cursor-pointer text-xs" @click="copyImage(image)"
              >Copy URL</span
            >
            <span
              class="absolute right-0 cursor-pointer"
              @click="removeImage(image.uuid)"
            >
              <feather-icon icon="Trash2Icon" />
            </span>
          </div>
          <!-- <div class="flex flex-col mb-1">
              <b-img src="https://picsum.photos/500/500/?image=54" fluid thumbnail></b-img>
              <span class="cursor-pointer text-xs">Copy URL</span>
          </div>
          <div class="flex flex-col mb-1">
              <b-img src="https://picsum.photos/500/500/?image=54" fluid thumbnail></b-img>
              <span class="cursor-pointer text-xs">Copy URL</span>
          </div>
          <div class="flex flex-col mb-1">
              <b-img src="https://picsum.photos/500/500/?image=54" fluid thumbnail></b-img>
              <span class="cursor-pointer text-xs">Copy URL</span>
          </div>
          <div></div> -->
        </div>
        <!-- <p>
        Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
        in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
        </p>
        <b-img src="https://picsum.photos/500/500/?image=54" fluid thumbnail></b-img> -->
      </div>
    </b-sidebar>
    <Modal
      id="modal-access-denied"
      :hideFooter="true"
      :no-close-on-backdrop="true"
      :hide-header="true"
      :hide-back-drop="true"
    >
      <access-denied />
    </Modal>
  </div>
</template>

<script>
import { EmailEditor } from "vue-email-editor";
import useAppConfig from "@core/app-config/useAppConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

import accessDenied from "@core/components/permission/accessDenied";
export default {
  name: "EditEmailTemplate",
  components: {
    EmailEditor,
    ValidationProvider,
    ValidationObserver,
    accessDenied,
  },
  data() {
    return {
      config: useAppConfig(),
      minHeight: "700px",
      localEditor: "",
      tools: {
        // disable html tool
        html: {
          enabled: false,
        },
      },
      processing: false,
      translate: {
        upload: "upload",
        uploadInvoiceTitle: "exportCard.uploadInvoiceTitle",
        alertPermited: "exportCard.alertPermited",
        downloadExcel: "exportCard.downloadExcel",
        memberTitle: "exportCard.memberTitle",
        articleFooter: "exportCard.articleFooter",
        exportLogDetail: "exportCard.exportLogDetails",

        mainTitle: "emailTemplateCard.mainTitle",
        title: "emailTemplateCard.title",
        updateDesign: "emailTemplateCard.updateDesign",
        saveTemDesign: "emailTemplateCard.saveDesign",
      },
      templateInfo: {
        title: "",
        html_content: "",
        raw_content: "",
      },
      templateImages: { results: [] },
    };
  },
  computed: {
    customMsg() {
      if (this.GET_LOCALE === "") {
        return this.swedishLang ? "message" : "eng_message";
      } else return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    message() {
      return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    features() {
      if (this.GET_LOCALE === "") {
        return this.swedishLang ? "features" : "features";
      } else return this.GET_LOCALE === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    locale_message() {
      return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    ...mapGetters({
      GET_LOCALE: "appConfig/getLocale",
    }),
  },
  mounted() {
    this.$store.commit("appConfig/UPDATE_ACCESS_DENIED", false);
    this.getTemplatePhotos();
    const { uuid } = this.$route.params;
    if (uuid) {
      this.getTemplateInfo(uuid);
    }
    if (this.config.lang === "") {
      this.locale = "sv";
    } else {
      this.locale = this.config.lang === "sv" ? "sv" : "en";
    }
  },
  methods: {
    editorLoaded() {
      // Pass the template JSON here
    },
    // called when the editor has finished loading
    editorReady() {
      const self = this;
      if (this.$route.params.uuid) {
        this.$refs.emailEditor.editor.loadDesign(this.templateInfo.raw_content);
      }

      this.$refs.emailEditor.editor.registerCallback(
        "image",
        function (file, done) {
          // Your code
          self.uploadImage(file, done);
        }
      );
    },
    sanitizeHTML(html) {
      return html?.replace(
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
        ""
      );
    },
    uploadImage(file, done) {
      let self = this;
      let formData = new FormData();
      let reader = new FileReader();

      reader.addEventListener("load", () => {
        let newImage = reader.result;
      });

      reader.readAsDataURL(file.attachments[0]);
      formData.append("image", file.attachments[0]);
      self.$useJwt
        .uploadMemberCareImage(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          done({ progress: 100, url: res.data.data.image });
          let formData = null;
          let reader = null;
        })
        .catch((err) => {
          self.popupMsg(
            self.$t("Message.Failed"),
            err.response.data[this.locale_message()],
            "AlertTriangleIcon",
            "danger"
          );
        });
    },
    saveTemplateInfo() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          const self = this;

          this.$refs.emailEditor.editor.exportHtml((data) => {
            this.processing = true;
            let edit = false;
            const mode = { URL: "", method: "" };
            const payload = {
              title: this.templateInfo.title,
              html_content: this.sanitizeHTML(data.html),
              raw_content: data.design,
            };
            const { uuid } = self.$route.params;
            if (uuid) {
              // Send update
              mode.URL = `newsletter/template/${uuid}`;
              mode.method = "put";
              edit = true;
            } else {
              // send create
              mode.URL = "newsletter/template";
              mode.method = "post";
              edit = false;
            }
            self.$useJwt
              .memberCare(mode, payload)
              .then((res) => {
                this.processing = false;
                this.popupMsg(
                  this.$t("Message.Success"),
                  res.data[this.customMsg],
                  "CheckIcon",
                  "success"
                );
                if (!edit) {
                  this.$router.replace({ name: "memberCareEmailSendout" });
                }
              })
              .catch((err) => {
                this.processing = false;
                this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
                );
              });
          });
        }
      });
    },
    removeImage(uuid) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Message.wait"),
          variant: "warning",
          text: "Processing image deletion",
          waiting: true,
        },
      });
      this.$useJwt
        .memberCare({ method: "delete", URL: `newsletter/images/${uuid}` })
        .then((res) => {
          this.getTemplatePhotos();
          this.popupMsg(
            this.$t("Message.Success"),
            res.data[this.message],
            "CheckIcon",
            "success"
          );
        })
        .catch((err) => {
          this.popupMsg(
            this.$t("Message.Failed"),
            err.response.data[this.message],
            "AlertTriangleIcon",
            "danger"
          );
        });
    },

    cleanDesign(design) {
      const cleanedDesign = { ...design };
      delete cleanedDesign.scripts;
      delete cleanedDesign.styles;
      return cleanedDesign;
    },

    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign((design) => {});
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {});
    },
    getTemplateInfo(uuid) {
      this.$useJwt
        .memberCare({ method: "get", URL: `newsletter/template/${uuid}` })
        .then((res) => {
          this.templateInfo = res.data.data;
        })
        .catch((er) => {
          if (er.response.status === 403) {
            this.$store.commit("appConfig/UPDATE_ACCESS_DENIED", true);
            this.$bvModal.show("modal-access-denied");
          }
        });
    },
    copyImage(data) {
      navigator.clipboard.writeText(data.image);
      this.popupMsg(
        this.$t("Message.Success"),
        "Copied URL",
        "CheckIcon",
        "info"
      );
    },
    getTemplatePhotos() {
      this.$useJwt
        .memberCare({ method: "get", URL: "newsletter/images?limit=200" })
        .then((res) => {
          this.templateImages = res.data;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.resource-selector {
  width: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/datepicker.scss";
</style>
